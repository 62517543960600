import React from "react";
import { Link } from "react-router-dom";
import pageBannerStart from "../../assets/images/page-banner-start.svg";
import pageBanner from "../../assets/images/contact-us.jpg";
import badgeLine from "../../assets/images/badge-line.svg";

const ContactUs = () => {
  return (
    <>
      <div className="header-gutter" />
      <section className="page-banner pt-xs-60 pt-sm-80 overflow-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="page-banner__content mb-xs-10 mb-sm-15 mb-md-15 mb-20">
                <div className="transparent-text">Contact</div>
                <div className="page-title">
                  <h1>
                    Contact <span>With Us</span>
                  </h1>
                </div>
              </div>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Contact Us</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div className="page-banner__media mt-xs-30 mt-sm-40">
                <img src={pageBannerStart} className="img-fluid start" alt="" />
                <img src={pageBanner} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="contact-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-us__content">
                <h6 class="sub-title fw-500 color-primary text-uppercase mb-sm-15 mb-xs-10 mb-20">
                  <img src={badgeLine} class="img-fluid mr-10" alt="" /> contact
                  us with Ease
                </h6>
                <h2 class="title color-d_black mb-sm-15 mb-xs-10 mb-20">
                  Get in Touch
                </h2>

                <div class="description font-la">
                  <p>
                    Get a clear picture of your assets' value with our trusted
                    valuation advisors. We offer a free quote and consultation
                    to discuss your specific needs. Ensure fair value in any
                    transaction.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row contact-us__item-wrapper mt-xs-35 mt-sm-40 mt-md-45">
                <div class="col-sm-6">
                  <div class="contact-us__item mb-40">
                    <div class="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div class="icon mr-10 color-primary">
                        <i class="fa-solid fa-location-dot"></i>
                      </div>

                      <h5 class="title color-d_black">Branch Office</h5>
                    </div>

                    <div class="contact-us__item-body font-la">
                      Office No 212 , 2nd Floor , Uma Complex, Nr Signet Mall,
                      Kamrej to Bardoli Road, Kamrej, Surat-394180
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="contact-us__item mb-40">
                    <div class="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div class="icon mr-10 color-primary">
                        <i class="icon-home-location"></i>
                      </div>

                      <h5 class="title color-d_black">Head Office</h5>
                    </div>

                    <div class="contact-us__item-body font-la">
                      Assured Consultancy Services , Office No 620, 6th Floor,
                      IFC(International Finance Center)Building, opp Vesu Fire
                      Station, VIP Road, Vesu, Surat-395007
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="contact-us__item mb-40">
                    <div class="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div class="icon mr-10 color-primary">
                        <i class="icon-phone"></i>
                      </div>

                      <h5 class="title color-d_black">Call Us Toll Free</h5>
                    </div>

                    <div class="contact-us__item-body font-la">
                      <ul>
                        <li>
                          <a href="tell:(+91)92744-29001">(+91) 92744 29001</a>
                        </li>
                        <li>
                          <a href="tell:(+91)79840-98104">(+91) 79840 98104 </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="contact-us__item mb-40">
                    <div class="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div class="icon mr-10 color-primary">
                        <i class="icon-email"></i>
                      </div>

                      <h5 class="title color-d_black">Email Us</h5>
                    </div>

                    <div class="contact-us__item-body font-la">
                      <ul>
                        <li>
                          <a href="mailto:acss@acss.in">acss@acss.in</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row">
            <div class="col-12">
              <hr class="mt-md-45 mt-sm-30 mt-xs-30 mt-60" />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default ContactUs;
